import React from 'react';
import styled from 'styled-components';

import { Header } from '../components/theme';
import { SEO } from '../components/common';

const NotFound = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
`;

const NotFoundPage = function () {
  return (
    <NotFound>
      <SEO title="404: Not found" location="/404" />
      <Header />
      <div>
        <h1>NOT FOUND</h1>
        <p>Seems like we cannot find the page that you are looking for.</p>
      </div>
    </NotFound>
  );
};

export default NotFoundPage;
